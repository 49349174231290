export const fontStack = `'FT Polar', sans-serif`;

export const fonts = {
  body: fontStack,
  heading: fontStack,
  mono: "'FT Polar Mono', monospace",
};

export const fontSizes = {
  1: '48px',
  2: '36px',
  3: '24px',
  4: '20px',
  5: '16px',
  6: '14px',
  7: '12px',
  11: '40px',
  12: '32px',
  13: '20px',
  14: '16px',
  15: '14px',
  16: '12px',
  17: '10px',
};

export const fontWeights = {
  1: 500,
  2: 500,
  3: 500,
  4: 500,
  5: 500,
  6: 500,
  7: 500,
  normal: 500,
  medium: 500,
  semibold: 500,
  bold: 500,
};

export const lineHeights = {
  1: 1.5,
  2: 1.55,
  3: 1.55,
  4: 1.6,
  5: 1.5,
  6: 1.7,
  7: 1.7,
  11: 1.2,
  12: 1.4,
  13: 1.6,
};
