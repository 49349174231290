export const textStyles = {
  display: {
    fontSize: 11,
    mb: 0,
    lineHeight: 11,
  },
  displayMono: {
    fontSize: 11,
    fontFamily: 'mono',
    mb: 0,
    lineHeight: 11,
  },
  heading1: {
    fontSize: 12,
    mb: 0,
    lineHeight: 11,
  },
  heading2: {
    fontSize: 13,
    mb: 0,
    lineHeight: 11,
  },
  heading2Bold: {
    fontSize: 13,
    fontWeight: 'bold',
    mb: 0,
    lineHeight: 11,
  },
  heading3: {
    fontSize: 14,
    mb: 0,
    lineHeight: 12,
  },
  heading3Bold: {
    fontSize: 14,
    fontWeight: 'bold',
    mb: 0,
    lineHeight: 12,
  },
  body1: {
    fontSize: 15,
    mb: 0,
    lineHeight: 12,
  },
  body1Bold: {
    fontSize: 15,
    fontWeight: 'bold',
    mb: 0,
    lineHeight: 12,
  },
  body1Mono: {
    fontSize: 15,
    fontFamily: 'mono',
    mb: 0,
    lineHeight: 13,
  },
  body2: {
    fontSize: 16,
    mb: 0,
    lineHeight: 12,
  },
  body2Mono: {
    fontSize: 16,
    fontFamily: 'mono',
    mb: 0,
    lineHeight: 11,
  },
  link: {
    fontSize: 15,
    color: 'primary.500',
    textDecoration: 'underline',
    mb: '0',
  },
  caption: {
    fontSize: 17,
    color: 'gray.700',
    mb: 0,
    lineHeight: 11,
  },
  captionMono: {
    fontSize: 17,
    fontFamily: 'mono',
    color: 'gray.700',
    mb: 0,
    lineHeight: 11,
  },
};
