import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import {
  Box,
  Flex,
  Textarea,
  Tooltip,
  useFormControl,
  useMergeRefs,
} from '@chakra-ui/react';
import { forwardRef, useStyleConfig } from '@chakra-ui/system';
import { FaMarkdown } from 'react-icons/fa';

import { useResizeEffect } from '../../hooks';
import { IconButton } from '../icon-button';

export const TextArea = forwardRef(function TextArea(
  {
    autoSize = false,
    onChange: onChangeProp,
    onKeyDown: onKeyDownProp,
    onCommandEnter: onCommandEnterProp,
    children,
    maxHeight = 600,
    rows = 2,
    hidden,
    value,
    ...props
  },
  ref,
) {
  const textAreaRef = useRef(null);
  const refs = useMergeRefs(textAreaRef, ref);
  const [minHeight, setMinHeight] = useState(16);

  const updateHeight = useCallback(() => {
    const textArea = textAreaRef.current;
    if (textArea) {
      const textArea = textAreaRef.current;

      if (textArea.scrollHeight <= parseInt(maxHeight)) {
        textArea.style.overflow = 'hidden';
        textArea.style.resize = 'none';

        textArea.style.height = '0px';

        textArea.style.height = `${Math.max(
          textArea.scrollHeight,
          minHeight,
        )}px`;
      } else {
        textArea.style.overflow = 'auto';
        textArea.style.resize = 'both';
        textArea.style.height = `${parseInt(maxHeight)}px`;
      }
    }
  }, [textAreaRef]);

  useLayoutEffect(() => {
    setMinHeight(textAreaRef.current.offsetHeight);
  }, []);

  useLayoutEffect(() => {
    if (autoSize && !hidden) {
      updateHeight();
    }
  }, [autoSize, hidden]);

  useResizeEffect(autoSize && !hidden && textAreaRef, updateHeight);

  useEffect(() => {
    if (autoSize && !hidden) {
      updateHeight();
    }
  }, [value, autoSize, hidden, updateHeight]);

  function handleChange(...args) {
    onChangeProp?.(...args);
    if (autoSize && !hidden) {
      updateHeight();
    }
  }

  function handleKeyDown(e, ...rest) {
    if (e.metaKey && e.which === 13) {
      onCommandEnterProp?.(e, ...rest);
    }
    onKeyDownProp?.(e, ...rest);
  }

  const textAreaProps = useFormControl({
    isDisabled: props.isDisabled,
    isInvalid: props.isInvalid,
  });

  const css = useStyleConfig('Textarea');
  css._focusWithin = textAreaProps['aria-invalid']
    ? css._invalid?._focusVisible
    : css?._focusVisible;

  return (
    <Box
      __css={css}
      overflow="hidden"
      height="auto"
      p="0"
      fontSize="inherit"
      lineHeight="inherit"
      direction="column"
      minHeight="unset"
      borderRadius="base"
      aria-invalid={textAreaProps['aria-invalid']}
      aria-readonly={textAreaProps['aria-readonly']}
    >
      <Textarea
        hidden={hidden}
        rows={rows}
        ref={refs}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        bg="transparent"
        border="none"
        boxShadow="none"
        borderBottomRadius={children ? '0' : css.borderRadius}
        fontSize="inherit"
        lineHeight="inherit"
        value={value}
        _active={{}}
        _focusVisible={{}}
        _hover={{}}
        _invalid={{}}
        _readOnly={{}}
        {...props}
      />
      {children && (
        <Box
          bg="transparent"
          mx="1px"
          onClick={() => textAreaRef.current?.focus()}
        >
          {children}
        </Box>
      )}
    </Box>
  );
});

export const TextAreaFooterMarkdownLink = ({ props }) => {
  const label = 'Supports a subset of basic Markdown syntax';
  return (
    <Flex
      py="1"
      px="2"
      alignItems="center"
      gap="2"
      justifyContent="flex-end"
      {...props}
    >
      <Tooltip label={label} hasArrow borderRadius="base" textAlign="center">
        <span>
          <IconButton
            as="a"
            href="https://firehydrant.com/docs/other-resources/markdown-support/"
            target="_blank"
            rel="noreferrer"
            aria-label={label}
            icon={<FaMarkdown />}
            variant="ghost"
            size="xs"
            color="gray.500"
            fontSize="16"
          />
        </span>
      </Tooltip>
    </Flex>
  );
};
